import { useCallback, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import { logoutRedirect } from "src/async/fetch";
import NavigationRouter from "src/components/navigation/NavigationRouter";
import Header from "src/components/shared/Header";
import Preloader from "src/components/shared/LoadingAnimations/Preloader";
import LogoutTimer from "src/components/shared/LogoutTimer";
import { outerPages } from "src/config/host";
import { AuthContext } from "src/context/auth";
import { SiteContext } from "src/context/site";
import { WorkspaceContext } from "src/context/workspace";
import { searchMenu } from "src/helpers";
import { classNames } from "src/helpers/classNames";
import useUrlQueryParams from "src/hooks/useUrlQueryParams";

const Dashboardlayout = ({ nested = false, publicGroup = false }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMenu, setIsMenu] = useState(true);
  const [menuType, setMenuType] = useState("vertical");
  // const [workspaceLoading, setWorkspaceLoading] = useState(true);
  const [workspaces, setWorkspaces] = useState([]);
  const [workspaceDetailsLoading, setWorkspaceDetailsLoading] = useState(true);
  const [workspaceDetails, setWorkspaceDetails] = useState({});
  const [workspacePaymentModalOpen, setWorspacePaymentModalOpen] = useState(false);
  const [planDetails, setPlanDetails] = useState({});
  const [isPlanDetailsLoaded, setIsPlanDetailsLoaded] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [menus, setMenus] = useState([]);
  const [menusLoaded, setMenusLoaded] = useState(false);

  const [simulatedMenus, setSimulatedMenus] = useState([]);

  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  const { site } = useContext(SiteContext);

  const [params, setParams] = useState({ workspace_id: null, group_id: null, page_id: null });

  const { workspace_id } = useParams();
  const { group: group_id, page: page_id } = useUrlQueryParams();

  useEffect(() => {
    setParams({ workspace_id, group_id, page_id });
  }, [workspace_id, group_id, page_id]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    if (windowWidth < 768) {
      setIsMenu(false);
    } else {
      setIsMenu(true);
    }
  }, [windowWidth, workspace_id]);

  const loadWorkspaces = useCallback(() => {
    (async () => {
      if (!user?._id && !publicGroup) return;

      setWorkspaceDetailsLoading(true);
      try {
        let url = "/portal/users/workspaces/list";
        if (publicGroup) url = `/portal/users/workspaces/list?workspace=${workspace_id}&group=${group_id}&page=${page_id}`;
        const { data: resData } = await apiRequest("GET", url);

        if (resData.status === 200) {
          if (!Array.isArray(resData.data) || resData.data.length === 0) {
            navigate("/permission-denied");
          }

          setWorkspaces(resData.data);
        }
      } catch (err) {
        toast.error("err", err);
      } finally {
        setWorkspaceDetailsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id]);

  const isOuterPage = outerPages.includes(window.location.pathname);

  useEffect(() => {
    const layout_type = workspaceDetails?.use_global_settings ? site?.layout_type : workspaceDetails?.layout_type;
    setMenuType(layout_type === "TOP_NAVIGATION" ? "horizontal" : "vertical");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDetails?.use_global_settings, site?.layout_type, workspaceDetails?.layout_type]);

  useEffect(() => {
    if (isOuterPage) {
      setWorkspaceDetails({});
    }
  }, [isOuterPage]);

  useEffect(() => {
    loadWorkspaces();
  }, [loadWorkspaces]);

  useEffect(() => {
    if (workspaces.length) {
      const currentWorkspace = workspaces.find((workspace) => workspace._id === workspace_id || workspace.slug === workspace_id);
      if (!currentWorkspace) navigate("/permission-denied");
      setWorkspaceDetails(currentWorkspace || workspaces[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces.length, workspace_id]);

  const loadMenus = useCallback(
    (from) => {
      const controller = new AbortController();
      const signal = controller.signal;
      (async () => {
        if (!workspace_id || !workspaceDetails?.slug || ![workspaceDetails?._id, workspaceDetails?.slug].includes(workspace_id)) return;

        try {
          if (from !== "menu") {
            setMenus([]);
            setSimulatedMenus([]);
            setMenusLoaded(true);
          }

          let url = `/portal/menu/${workspace_id}`;
          if (publicGroup) url = `/portal/menu/${workspace_id}?group=${group_id}&page=${page_id}`;

          const { data: menuData } = await apiRequest("GET", url, {}, { signal });

          if (menuData.status === 200) {
            const individualMenus = menuData.data.individualMenu.map((item) => ({
              ...(item.page_id || {}),
              type: item.page_id?.page_type !== "EXTERNAL_LINK" ? "internal" : "external",
              link: item.page_id?.page_type !== "EXTERNAL_LINK" ? `/workspace/${workspaceDetails?.slug || workspace_id}/embed?page=${item.page_id?.slug || item.page_id?._id}` : item.page_id?.page_link,
            }));

            const groupMenus = menuData.data.menu.map((item) => ({
              ...item,
              type: "group",
              isOpen: item.slug === group_id || item._id === group_id,
              submenu: item.permissions.map((permission) => ({
                ...permission.page,
                groupId: item.slug || item._id,
                isOpen: item.slug === group_id || item._id === group_id,
                type: permission.page?.page_type !== "EXTERNAL_LINK" ? "internal" : "external",
                link: permission.page?.page_type !== "EXTERNAL_LINK" ? `/workspace/${workspaceDetails?.slug || workspace_id}/embed?group=${item.slug || item._id}&page=${permission.page?.slug || permission.page?._id}` : permission.page?.page_link,
              })),
            }));

            let finalGroupMenu = [];
            for (const groupMenu of groupMenus) {
              if (groupMenu?.flatten_pages) {
                for (const sub of groupMenu.submenu) {
                  finalGroupMenu.push({ ...sub, sub: true });
                }
              } else {
                finalGroupMenu.push(groupMenu);
              }
            }

            let finalMenu = [...individualMenus, ...finalGroupMenu];

            if (!individualMenus.length && finalGroupMenu.length === 1) {
              finalMenu = finalGroupMenu.flatMap((item) => item.submenu.map((item) => ({ ...item, sub: true })));
            }

            setMenus(finalMenu);
            if (!keyword) {
              setSimulatedMenus(finalMenu);
            } else {
              setSimulatedMenus(searchMenu(finalMenu, keyword));
            }
            setMenusLoaded(true);
          }
        } catch (err) {
          // toast.error(err.message);
        }
      })();
      return () => {
        controller.abort();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workspace_id, workspaceDetails?.slug, workspaceDetails?._id],
  );

  useEffect(() => {
    const abortLoadMenus = loadMenus();
    return () => {
      abortLoadMenus();
    };
  }, [loadMenus]);

  const containerClass = {
    default: "w-full flex flex-grow transition-all bg-backgroundColor",
    vertical: classNames(
      "w-full flex flex-grow transition-all justify-center overflow-auto bg-backgroundColor",
      isMenu && !isOuterPage && workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" && menus.length > 1 && menuType === "vertical" ? " md:max-w-[calc(100%-18rem)] ml-0 md:ml-80 xl:ml-96 justify-center" : "max-w-full ml-0",
    ),
    horizontal: classNames(
      "w-full flex flex-grow transition-all justify-center overflow-auto bg-backgroundColor",
      isMenu && !isOuterPage && workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" && menus.length > 1 && menuType === "vertical" ? " md:max-w-[calc(100%-18rem)] ml-0 md:ml-80 xl:ml-96 justify-center h-full" : "max-w-full ml-0 ",
    ),
  };

  useEffect(() => {
    if (windowWidth < 768) {
      if (isMenu) {
        document.querySelector('html').style.overflow = 'hidden';
        document.querySelector('body').style.overflow = 'hidden';
      } else {
        document.querySelector('html').style.overflow = '';
        document.querySelector('body').style.overflow = '';
      }
    }
  }, [isMenu, windowWidth])

  if (!site?._id) {
    return (
      <div className={classNames("flex h-full flex-grow items-center justify-center pt-16")}>
        <Preloader />
      </div>
    );
  }

 



  return (
    <WorkspaceContext.Provider
      value={{
        loading: workspaceDetailsLoading,
        setLoading: setWorkspaceDetailsLoading,
        list: workspaces,
        setList: setWorkspaces,
        details: workspaceDetails,
        setDetails: setWorkspaceDetails,
        workspacePaymentModalOpen,
        setWorspacePaymentModalOpen,
        planDetails,
        setPlanDetails,
        isPlanDetailsLoaded,
        setIsPlanDetailsLoaded,
        menuType,
        params,
      }}>
      {!nested ? (
        <div className="flex min-h-screen flex-col">
          <Header
            isMenu={isMenu}
            setIsMenu={setIsMenu}
            menusLoaded={menusLoaded}
            menuType={menuType}
            menus={menus}
          />
          {workspaceDetailsLoading && !outerPages.includes(window.location.pathname) ? (
            <div className={classNames("flex h-full flex-grow items-center justify-center pt-16")}>
              <Preloader />
            </div>
          ) : (
            <div className={classNames("flex min-h-screen pt-16", menuType !== "vertical" ? "flex-col" : "")}>
              {outerPages.includes(window.location.pathname) ? (
                <></>
              ) : workspaceDetails?.workspace_type === "JWT_FULL_EMBED" ? (
                <></>
              ) : (
                <>
                  {menus.length > 1 && (
                    <NavigationRouter
                      menuType={menuType}
                      isMenu={isMenu}
                      setIsMenu={setIsMenu}
                      menus={menus}
                      setMenus={setMenus}
                      simulatedMenus={simulatedMenus}
                      setSimulatedMenus={setSimulatedMenus}
                      menusLoaded={menusLoaded}
                      keyword={keyword}
                      setKeyword={setKeyword}
                      handleMenuClick={() => loadMenus("menu")}
                    />
                  )}
                </>
              )}
              <div className={containerClass[menuType]}>
                <Outlet />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="bg-backgroundColor">
          <Outlet />
        </div>
      )}
      {site?._id && user?._id && site?.user_inactivity_logout_enabled > 0 && (
        <LogoutTimer
          site={site}
          me={user}
          onLogout={logoutRedirect}
        />
      )}
    </WorkspaceContext.Provider>
  );
};

export default Dashboardlayout;
